@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-300.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-regular.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-500.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-600.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-700.woff2') format('woff2');
}

@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 300;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-300.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 400;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-regular.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 500;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-500.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 600;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-600.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 700;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-700.woff2') format("woff2");
}

body{
    font-family: Poppins, NotoSansArmenian, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@import 'home';
@import '../../global/tenants-global';


:root {
    --nav-background: #ffffff;
    --nav-link: #000000;
    --nav-link-hover: #19B6CB;


    --btn1-background-color: #19B6CB;
    --btn1-background-hover-color: #149cae;
    --btn1-text-color: #ffffff;
    --btn1-text-hover-color: #ffffff;

    --btn1-outline-background-color: #ffffff;
    --btn1-outline-background-hover-color: #19B6CB;
    --btn1-outline-text-color: #000000;
    --btn1-outline-text-color-hover: #ffffff;
    --btn1-outline-border-color: #19B6CB;
    --btn1-outline-border-hover-color: #19B6CB;


    --btn2-background-color: #30384D;
    --btn2-background-hover-color: #242b3d;
    --btn2-text-color: #ffffff;
    --btn2-text-hover-color: #ffffff;

    --btn2-outline-background-color: #ffffff;
    --btn2-outline-background-hover-color: #30384D;
    --btn2-outline-text-color: #000000;
    --btn2-outline-text-color-hover: #ffffff;
    --btn2-outline-border-color: #30384D;
    --btn2-outline-border-hover-color: #30384D;


    --color-1: #19B6CB;
    --color-2: #30384D;

    --course-border: transparent;
    --course-radius: 0;
    --course-background: #ffffff;
    --course-background-hover: #30384D;
    --course-color: #1D1D1D;
    --course-color-hover: #ffffff;
    --course-color-hr: #E2DADA;
    --course-color-hr-hover: #30384D;
}
h1,h2,h3,h4,h5,h6, p{
    min-height: 17px;
}

.btn{
    border-radius: 0;
}
.home-course-item-block hr{
    display: none;
}


.theme-9-info-section{
    padding: 35px 12px;

    @media (max-width: 767px){
        padding: 25px 12px;
    }
    .theme-all-info-section-2-container{
        padding: 35px 12px;

        @media (max-width: 767px){
            padding: 25px 12px;
        }
    }
}
