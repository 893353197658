
.home-h2{
    margin-bottom: 12px;
    font-size: 34px;
    font-weight: 400;
    letter-spacing: .45px;
    text-align: center;

    @media (max-width: 1159px) {
        font-size: 30px;
    }
    @media (max-width: 767px) {
        font-size: 26px;
        margin-bottom: 8px;

        &:lang(hy){
            font-size: 22px;
        }
    }
}

.home-header{
    min-height: 41.8vw;
    color: #ffffff;
    width: 100%;
    background-color: black;
    padding: 20px;

    img{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        object-fit: cover;
    }
    .home-header-text{
        padding: 63px 67px;
        z-index: 1;
        background-color: rgba(48, 56, 77, 0.65);
        max-width: 814px;

        @media (max-width: 991px){
            padding: 40px 40px;
        }
        @media (max-width: 575px){
            padding: 30px 20px;
        }
        h1{
            font-size: 55px;
            line-height: 1.4;
            margin-bottom: 10px;
            font-weight: 400;

            @media (max-width: 991px) {
                font-size: 40px;
            }
            @media (max-width: 575px) {
                font-size: 30px;
                margin-bottom: 10px;
                line-height: 1.3;
            }
            &:lang(hy){
                font-size: 44px;

                @media (max-width: 991px) {
                    font-size: 38px;
                }
                @media (max-width: 767px) {
                    font-size: 26px;
                }
            }
        }
        p{
            font-size: 18px;
            letter-spacing: .55px;

            @media (max-width: 767px){
                font-size: 16px;
                line-height: 1.4;
            }
        }
    }
}
.home-service{
    padding: 60px 0 36px;

    @media (max-width: 767px){
        padding: 36px 0 12px;
    }
}
.home-service-container{
    margin-left: -12px;
    margin-right: -12px;
    justify-content: space-between;
    display: flex;
    padding: 12px 0;

    @media (max-width: 767px){
        flex-wrap: wrap;
    }
}
.home-service-item{
    width: 33.33%;
    padding: 12px;
    max-width: 400px;

    @media (max-width: 767px) {
        width: 100%;
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
    }
    > img{
        margin-bottom: 12px;
    }
    > h3{
        font-size: 20px;
        margin-bottom: 24px;
        color: #30384D;
        font-weight: 600;

        @media (max-width: 991px){
            font-size: 18px;
            margin-bottom: 12px;
        }
    }
    > p{
        margin-bottom: 0;
        line-height: 1.57;

        @media (max-width: 991px){
            line-height: 1.4;
            font-size: 14px;
        }
    }
}
.home-video-lesson{
    padding: 60px 0 60px;
    background-color: #F5F5F5;

    @media (max-width: 767px){
        padding: 36px 0 36px;
    }
}
.home-studies-section{

    @media (max-width: 767px){
        position: relative;
        padding: 30px 20px;

        > img{
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.home-studies{
    max-width: 948px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    min-height: 209px;
    margin: -170px auto 0;

    @media (max-width: 991px){
        max-width: 750px;
    }
    @media (max-width: 767px){
        flex-direction: column;
        align-items: center;
        margin: 0;
    }
}
.home-studies-left{
    background-color: rgba(48, 56, 77, 0.93);
    max-width: 660px;
    width: 100%;
    color: #ffffff;
    padding: 53px 40px;
    border-left: solid 7px var(--color-1);

    @media (max-width: 991px){
        padding: 24px;
        max-width: 540px;
    }
    @media (max-width: 767px){
        padding: 20px;
        margin-bottom: 21px;
    }
    > h2{
        font-size: 30px;
        margin-bottom: 30px;

        @media (max-width: 991px) {
            font-size: 26px;
            margin-bottom: 20px;
        }
        @media (max-width: 767px){
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
    > p{
        font-size: 18px;
        font-weight: 300;
        letter-spacing: .35px;
        margin-bottom: 9px;

        @media (max-width: 991px) {
            font-size: 16px;
            letter-spacing: 0;
            margin-bottom: 0;
        }
        @media (max-width: 767px) {
            font-size: 15px;
        }
    }
}
.home-student-section{
    padding-top: 100px;
    padding-bottom: 270px;

    @media (max-width: 767px){
        padding: 0;
    }
}
.home-student {
    width: 100%;
    padding: 60px 0 0;
    margin: 0 auto;
    background-color: #F5F5F5;

    @media (max-width: 767px){
        padding: 36px 0 0;
    }
    .home-student-info-slider {
        margin-bottom: -200px;
        margin-left: -12px;
        margin-right: -12px;
        padding: 12px 0;

        @media (max-width: 767px){
            flex-direction: column;
            margin-bottom: 0;
        }
        .description {
            padding: 12px;
            width: 25%;

            @media (max-width: 991px) {
                width: 50%;
            }
            @media (max-width: 767px){
                width: 100%;
            }
            img {
                display: block;
                margin: 0 auto -45px;
                height: 85px;
                width: 85px;
                object-fit: cover;
                border-radius: 50%;
                transition: all .3s;
            }
            p{
                line-height: 1.85;
                font-size: 14px;
                margin-bottom: 10px;

                @media (max-width: 991px){
                    font-size: .95rem;
                }
                @media (max-width: 767px){
                    font-size: .9rem;
                    line-height: 1.5;
                }
            }
            h3{
                font-size: 18px;
                margin-bottom: 14px;
                font-weight: 600;
                color: black;
                text-align: center;
            }
        }
    }
}
.description-item{
    background-color: #ffffff;
    padding: 60px 21px 21px;
    border: solid 1px #DFE2EE;

    @media (max-width: 767px){
        padding: 60px 16px 10px;
    }
}
.theme-all-form .theme-all-form-bg{
    opacity: 1;
}
